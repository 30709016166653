// eslint-disable-next-line import/prefer-default-export
export const isPDP = () => /^(\/ca)?\/[\w-]+\.html$/.test(window.location.pathname);
export const isPLP = () =>
  /^\/(ca\/?)?$/.test(window.location.pathname) && window.location.search.length > 0;
export const isCartPage = () => /^(\/ca)?\/cart\/buy\/?$/.test(window.location.pathname);
export const isHomePage = () =>
  /^(\/ca)?\/$/.test(window.location.pathname) && window.location.search.length === 0;
export const isCheckoutPage = () => /^(\/ca)?\/checkout\/?$/.test(window.location.pathname);
// eslint-disable-next-line consistent-return
export const getPageName = () => {
  if (isPDP()) return "pdp";
  if (isPLP()) return "plp";
  if (isHomePage()) return "home";
  if (isCartPage()) return "cart";
  if (isCheckoutPage()) return "checkout";
};
