import { track as trackMixpanel } from "jiffy-analytics";
import { trackRudderstackChatInteraction } from "common/utils/rudderstack/tracker";

(function () {
  function onTidioChatApiReady() {
    // Track messages from visitors
    window.tidioChatApi.on("messageFromVisitor", message => {
      trackMixpanel("tidio_chat_user_event", { action: "messageFromVisitor", message });
      trackRudderstackChatInteraction({ action: "messageFromVisitor", message });
    });

    // Track operator messages
    window.tidioChatApi.on("messageFromOperator", message => {
      trackMixpanel("tidio_chat_user_event", { action: "messageFromOperator", message });
      trackRudderstackChatInteraction({ action: "messageFromOperator", message });
    });

    // Track chat widget open and close
    window.tidioChatApi.on("open", () => {
      trackMixpanel("tidio_chat_user_event", { action: "open" });
      trackRudderstackChatInteraction({ action: "open" });
    });

    window.tidioChatApi.on("close", () => {
      trackMixpanel("tidio_chat_user_event", { action: "close" });
      trackRudderstackChatInteraction({ action: "close" });
    });

    // Track conversation start
    window.tidioChatApi.on("conversationStart", () => {
      trackMixpanel("tidio_chat_user_event", { action: "conversationStart" });
      trackRudderstackChatInteraction({ action: "conversationStart" });
    });

    // Track conversation start
    window.tidioChatApi.on("preFormFilled", data => {
      trackMixpanel("tidio_chat_user_event", { action: "preFormFilled", data });
      trackRudderstackChatInteraction({ action: "preFormFilled", data });
    });

    const tidioChatIframe = document.querySelector("#tidio-chat-iframe");

    if (tidioChatIframe) {
      tidioChatIframe.style.zIndex = "var(--z-index-tidio-chat)";
    }
  }

  if (window.tidioChatApi) {
    window.tidioChatApi.on("ready", onTidioChatApiReady);
  } else {
    document.addEventListener("tidioChat-ready", onTidioChatApiReady);
  }
})();
